<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4028 0H5.65281C4.89343 0 4.27777 0.688802 4.27777 1.53852C4.27777 2.38818 4.89343 3.07692 5.65281 3.07692H19.4028C20.1622 3.07692 20.7778 2.38818 20.7778 1.53852C20.7778 0.688802 20.1622 0 19.4028 0Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.04698 1.53109C3.04528 1.12393 2.88418 0.734128 2.59883 0.447092C2.00623 -0.149031 1.04924 -0.149031 0.456639 0.447092C0.171281 0.734128 0.0101827 1.12393 0.00848048 1.53109C-0.00282682 1.63064 -0.00282682 1.73129 0.00848048 1.83083C0.0256238 1.93099 0.0537097 2.02905 0.0920086 2.12293C0.132861 2.21399 0.18113 2.30147 0.236329 2.3844C0.29092 2.47053 0.35463 2.55051 0.426243 2.62261C0.495424 2.69238 0.571779 2.75427 0.654213 2.80718C0.734215 2.86612 0.820904 2.91521 0.912457 2.95323C1.01313 3.00614 1.12049 3.0449 1.23149 3.06853C1.32985 3.07972 1.42931 3.07972 1.52779 3.06853C1.9289 3.06889 2.31395 2.90857 2.59883 2.62261C2.67056 2.55051 2.73415 2.47053 2.78874 2.3844C2.84406 2.30147 2.89233 2.21399 2.93306 2.12293C2.98157 2.03016 3.01975 1.93222 3.04698 1.83083C3.05841 1.73129 3.05841 1.63064 3.04698 1.53109Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.04678 8.30013C3.05812 8.20047 3.05812 8.09995 3.04678 8.00029C3.02044 7.90112 2.98204 7.80565 2.93254 7.71596C2.89329 7.62159 2.84476 7.53153 2.78783 7.44688C2.73504 7.36161 2.67091 7.2841 2.5974 7.21631C2.00319 6.6202 1.04359 6.6202 0.449379 7.21631C0.163244 7.50323 0.00158489 7.893 0 8.30013C0.00292596 8.50276 0.041573 8.70307 0.114234 8.89205C0.152394 8.98297 0.198234 9.07045 0.251267 9.15337C0.309298 9.23691 0.37562 9.31418 0.449379 9.38394C0.516676 9.45801 0.593482 9.52273 0.677847 9.57612C0.758067 9.63518 0.844993 9.68415 0.936795 9.72217C1.02969 9.76166 1.12686 9.79008 1.22634 9.80681C1.32375 9.82884 1.42348 9.83917 1.52333 9.83757C1.62208 9.84901 1.72168 9.84901 1.82043 9.83757C1.91736 9.82072 2.01196 9.7923 2.1023 9.75292C2.19654 9.71515 2.28603 9.66618 2.36881 9.60688C2.45317 9.55348 2.5301 9.48877 2.5974 9.4147C2.67079 9.34679 2.7348 9.26927 2.78783 9.18413C2.84635 9.10318 2.89499 9.01545 2.93254 8.92281C2.9846 8.82069 3.023 8.71217 3.04678 8.59996C3.05848 8.5003 3.05848 8.39966 3.04678 8.30013V8.30013Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.04678 14.454C3.05799 14.3544 3.05799 14.2539 3.04678 14.1542C3.02045 14.0526 2.98205 13.9545 2.93255 13.8621C2.89159 13.7711 2.84319 13.6836 2.78773 13.6008C2.73482 13.5157 2.67082 13.4381 2.59731 13.3701C2.00326 12.7741 1.0436 12.7741 0.449551 13.3701C0.376041 13.4381 0.31204 13.5157 0.259133 13.6008C0.203665 13.6836 0.155268 13.7711 0.114429 13.8621C0.0748094 13.9558 0.0467708 14.0538 0.0305572 14.1542C0.0091016 14.2526 -0.00101668 14.3532 8.04837e-05 14.454C0.00178718 14.861 0.163436 15.2508 0.449551 15.5378C0.516844 15.6119 0.593645 15.6766 0.678005 15.73C0.75822 15.7891 0.845139 15.838 0.936935 15.876C1.02983 15.9155 1.12699 15.944 1.22634 15.9606C1.32387 15.9827 1.42359 15.993 1.52343 15.9913C1.62205 16.0029 1.72177 16.0029 1.82052 15.9913C1.91743 15.9746 2.01203 15.9462 2.10224 15.9068C2.1966 15.869 2.28608 15.8199 2.36885 15.7608C2.45321 15.7074 2.53001 15.6427 2.59731 15.5686C2.67082 15.5007 2.73482 15.4232 2.78773 15.338C2.84636 15.2572 2.895 15.1694 2.93255 15.0766C2.98448 14.9746 3.02288 14.8661 3.04678 14.7538C3.05848 14.6542 3.05848 14.5536 3.04678 14.454V14.454Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6367 6.76923H5.64105C4.88817 6.76923 4.27777 7.45809 4.27777 8.30775C4.27777 9.15741 4.88817 9.84615 5.64105 9.84615H20.6367C21.3896 9.84615 22 9.15741 22 8.30775C22 7.45809 21.3896 6.76923 20.6367 6.76923V6.76923Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5228 12.9231H5.64381C4.88941 12.9231 4.27777 13.6118 4.27777 14.4616C4.27777 15.3113 4.88941 16 5.64381 16H14.5228C15.2772 16 15.8889 15.3113 15.8889 14.4616C15.8889 13.6118 15.2772 12.9231 14.5228 12.9231Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "#000" },
  },
};
</script>