<template>
  <div class="view">
    <header class="view__header">
      <p v-if="designtype === 'private'" class="text">View</p>
      <IconButton
        :class="{
          'button--outline-grey button--md button--brd-right-0': designtype === 'private',
          'button--public button--brd-right-0': designtype === 'public',
          'button--selected': designtype === 'private' && type === 'card',
          'button--public-selected': designtype === 'public' && type === 'card',
        }"
        @click="type = 'card'"
      >
        <IconViewCards :width="designtype === 'public' ? '15' : '19'"></IconViewCards>
      </IconButton>
      <IconButton
        :class="{
          'button--outline-grey button--md button--brd-left-0': designtype === 'private',
          'button--public button--brd-left-0': designtype === 'public',
          'button--selected': designtype === 'private' && type === 'list',
          'button--public-selected': designtype === 'public' && type === 'list',
        }"
        @click="type = 'list'"
      >
        <IconListView :width="designtype === 'public' ? '17' : '22'"></IconListView>
      </IconButton>
    </header>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconListView from "./icons/IconListView.vue";
import IconViewCards from "./icons/IconViewCards.vue";
export default {
  components: {
    IconButton,
    IconListView,
    IconViewCards,
  },
  props: {
    designtype: { type: "public" | "private", default: "private" },
    viewType: { type: "list" | "card", default: "list" },
  },
  data() {
    return {
      type: "list",
    };
  },
  created() {
    this.type = this.viewType;
  },
  watch: {
    type(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.view {
  margin-left: 2rem;
  .text {
    @include type($fw: 700);
    color: $black;
    margin-right: 2rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &.public {
    .text {
      display: none;
    }
  }
}
</style>

