<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.72727 9H8.27267C8.67412 9 9 8.67412 9 8.27267V1.72727C9 1.32581 8.67412 1 8.27267 1H1.72727C1.32581 1 1 1.32581 1 1.72727V8.27267C1 8.67412 1.32581 9 1.72727 9Z"
      :stroke="color"
      stroke-width="1.2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2728 1H10.7272C10.3258 1 10 1.32581 10 1.72727V8.27267C10 8.67412 10.3258 9 10.7272 9H17.2728C17.6742 9 18 8.67412 18 8.27267V1.72727C18 1.32581 17.6742 1 17.2728 1Z"
      :stroke="color"
      stroke-width="1.2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.72727 19H8.27267C8.67412 19 9 18.6742 9 18.2728V11.7272C9 11.3258 8.67412 11 8.27267 11H1.72727C1.32581 11 1 11.3258 1 11.7272V18.2728C1 18.6742 1.32581 19 1.72727 19Z"
      :stroke="color"
      stroke-width="1.2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2728 11H10.7272C10.3258 11 10 11.3258 10 11.7272V18.2728C10 18.6742 10.3258 19 10.7272 19H17.2728C17.6742 19 18 18.6742 18 18.2728V11.7272C18 11.3258 17.6742 11 17.2728 11Z"
      :stroke="color"
      stroke-width="1.2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "#000" },
  },
};
</script>