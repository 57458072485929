var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view"},[_c('header',{staticClass:"view__header"},[(_vm.designtype === 'private')?_c('p',{staticClass:"text"},[_vm._v("View")]):_vm._e(),_c('IconButton',{class:{
        'button--outline-grey button--md button--brd-right-0': _vm.designtype === 'private',
        'button--public button--brd-right-0': _vm.designtype === 'public',
        'button--selected': _vm.designtype === 'private' && _vm.type === 'card',
        'button--public-selected': _vm.designtype === 'public' && _vm.type === 'card',
      },on:{"click":function($event){_vm.type = 'card'}}},[_c('IconViewCards',{attrs:{"width":_vm.designtype === 'public' ? '15' : '19'}})],1),_c('IconButton',{class:{
        'button--outline-grey button--md button--brd-left-0': _vm.designtype === 'private',
        'button--public button--brd-left-0': _vm.designtype === 'public',
        'button--selected': _vm.designtype === 'private' && _vm.type === 'list',
        'button--public-selected': _vm.designtype === 'public' && _vm.type === 'list',
      },on:{"click":function($event){_vm.type = 'list'}}},[_c('IconListView',{attrs:{"width":_vm.designtype === 'public' ? '17' : '22'}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }